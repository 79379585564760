import React from 'react';
import {
    AppError,
    DialogErrorContainer,
    FeedbackDialog,
    Icon,
    IconNames,
    ServerError,
    SnackBar,
    SnackBarTypes,
} from 'design-system';
import { styled } from 'styled-components';
import { useFeedbackController } from './feedbackController';
import { MessageType } from '../../../store/slices/feedbackSlice';

const Container = styled.div`
    position: absolute;
    z-index: 1500;
`;

const StyledSnackBarAction = styled(SnackBar)`
    width: auto !important;
`;

const Feedback = () => {
    const { firstMessage, clearMessage, buildActionHandler, getSnackbarTypeFromSeverity } = useFeedbackController();

    if (!firstMessage) return null;

    const renderServerErrorDialog = () => (
        <DialogErrorContainer>
            <ServerError onAction={buildActionHandler()} />
        </DialogErrorContainer>
    );

    const renderClientErrorDialog = () => (
        <DialogErrorContainer>
            <AppError onAction={buildActionHandler()} />
        </DialogErrorContainer>
    );

    const renderFeedbackDialog = () => (
        <FeedbackDialog
            title={firstMessage.title}
            icon={<Icon icon={IconNames.FEEDBACK_WARNING} />}
            content={firstMessage.content}
            actionLabel="Got it!"
            onAction={clearMessage}
        />
    );

    const renderSnackbar = () => {
        const snackBarType = firstMessage.actionType ? SnackBarTypes.ACTION : SnackBarTypes.SIMPLE;

        return (
            <StyledSnackBarAction
                buttonText={firstMessage.title}
                open={true}
                message={firstMessage.content}
                state={getSnackbarTypeFromSeverity()}
                onClose={clearMessage}
                type={snackBarType}
                action={buildActionHandler()}
                ignoreClickAway={snackBarType === SnackBarTypes.ACTION}
            />
        );
    };

    const renderComponent = () => {
        switch (firstMessage.type) {
            case MessageType.DIALOG:
                return renderFeedbackDialog();
            case MessageType.SERVER_ERROR_DIALOG:
                return renderServerErrorDialog();
            case MessageType.CLIENT_ERROR_DIALOG:
                return renderClientErrorDialog();
            case MessageType.SNACKBAR:
                return renderSnackbar();
            default:
                return;
        }
    };

    return <Container>{renderComponent()}</Container>;
};

export default Feedback;

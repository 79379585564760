export const enum FieldType {
    TEXT = 'text',
    NUMBER = 'numeric',
    PICK_LIST = 'picklist',
    ADDRESS = 'address',
    DATE_TIME = 'datetime',
    LOOK_UP = 'lookup',
}

export const enum DisplayType {
    AS_TITLE = 'AS_TITLE',
    AS_SUBTITLE = 'AS_SUBTITLE',
    AS_DETAILS = 'AS_DETAIL',
}

export const enum SortingOrderDirectionType {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

export const enum AccuracyType {
    ROOFTOP = 'ROOFTOP',
    RANGE_INTERPOLATED = 'RANGE_INTERPOLATED',
    GEOMETRIC_CENTER = 'GEOMETRIC_CENTER',
    APPROXIMATE = 'APPROXIMATE',
}

export type CRMFieldMap = {
    crmFieldName: string;
    crmFieldType: 'string' | 'number' | 'boolean';
};

export type CRMAddressMap = {
    city: CRMFieldMap;
    country: CRMFieldMap;
    zipCode: CRMFieldMap;
    state: CRMFieldMap;
    street: CRMFieldMap;
    latitude: CRMFieldMap | null;
    longitude: CRMFieldMap | null;
};

export type BaseField = {
    type: FieldType;
    id: string;
    name: string;
    label: string;
    isMandatory: boolean;
    isSearchable: boolean;
    isFilterable: boolean;
    isColorizable: boolean;
    isViewOnly: boolean;
    displayOrder: number;
    sortingOrder: number;
    sortingOrderDirection: SortingOrderDirectionType;
    displayType: DisplayType;
    crmMapping: CRMFieldMap | CRMAddressMap | null;
};

export type TextField = BaseField & {
    type: FieldType.TEXT;
    isTextArea: boolean;
    maxLength: number;
};

export type NumericField = BaseField & {
    type: FieldType.NUMBER;
    minValue: number | null;
    maxValue: number | null;
    decimals: number | null;
};

export type PickListOption = {
    id: string | null;
    label: string;
    value: string;
    order: number;
};

export type PickListField = BaseField & {
    type: FieldType.PICK_LIST;
    options: PickListOption[];
};

export type AddressField = BaseField & {
    type: FieldType.ADDRESS;
    isMain: boolean;
    accuracyTypes: AccuracyType[];
};

export type DateTimeField = BaseField & {
    type: FieldType.DATE_TIME;
};

export type LookUpField = BaseField & {
    type: FieldType.LOOK_UP;
    relatedObjectTypes: string[];
};

export type Field = TextField | NumericField | PickListField | AddressField | DateTimeField | LookUpField;

export type MainRelatedObject = {
    relatedObjectType: string;
    lookUpField: string;
};

export type CRMFieldType = 'string' | 'number' | 'boolean';

export type MetadataField = {
    crmName: string;
    type: CRMFieldType;
};

export type MetadataFields = {
    externalOwnerId: MetadataField;
    externalRecordId: MetadataField;
    lastModificationDate: MetadataField;
    creationDate: MetadataField;
};

export type CRMExtraIntegratedField = {
    fieldName: string;
    crmName: string;
    type: CRMFieldType;
};

export type ObjectIntegrationConfiguration = {
    id: string | null;
    mappingId: string | null;
    objectType: string;
    crmObjectType: string;
    label: string;
    labelPlural: string;
    isRoot: boolean;
    customJs: string;
    metadataFields: MetadataFields;
    fields: Field[];
    extraIntegratedFields: CRMExtraIntegratedField[];
    externalRecordUrlTemplate: string | null;
    mainRelatedObject: MainRelatedObject | null;
};

export type IntegrationConfiguration = {
    objectIntegrationConfigurations: ObjectIntegrationConfiguration[];
};

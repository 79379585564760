import styled from 'styled-components';
import Box from '@mui/material/Box';
import ProtectedRoute from '../components/ProtectedRoute';
import MainMenu from '../components/MainMenu/MainMenu';
import ErrorBoundaryComponent from '../components/Errors/ErrorBoundaryComponent';
import { Outlet } from 'react-router-dom';
import { usePageLayoutWithMenuControllerType } from './hooks/usePageLayoutWithMenuController';
import Feedback from '../components/Feedback/Feedback';
import { useFetchRequired } from './hooks/useFetchRequired';
import { LinearProgress } from '@mui/material';

const StyledRootBox = styled(Box)`
    display: flex;
    width: 100%;
    height: 100%;
`;

const condensedMenuWidth = '65px';

const StyledPageBox = styled(Box)<any>`
    width: ${({ theme, open }) => `calc(100% - ${open ? theme.palette.Measures.width.menu : condensedMenuWidth})`};
    height: 100%;
`;

const StyledLinearProgress = styled(LinearProgress)`
    & .MuiLinearProgress-bar {
        background: ${({ theme }) => theme.palette.Background.button.mainMenu.enabled};
    }
`;

export default function PageLayoutWithMenu({ useController }: { useController: usePageLayoutWithMenuControllerType }) {
    const { open, handleDrawerOpen, handleDrawerClose } = useController();
    const { isLoading } = useFetchRequired();

    return (
        <>
            <ProtectedRoute>
                <StyledRootBox>
                    <MainMenu
                        isMenuOpen={open}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose}
                    />
                    <ErrorBoundaryComponent>
                        <StyledPageBox data-testid={'t-main-page'} open={open}>
                            {isLoading ? <StyledLinearProgress /> : <Outlet />}
                            <Feedback />
                        </StyledPageBox>
                    </ErrorBoundaryComponent>
                </StyledRootBox>
            </ProtectedRoute>
        </>
    );
}

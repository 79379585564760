import { backendHost } from '../../common/configuration/ConfigProvider';
import { RootState } from '../store';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
    baseUrl: backendHost,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).userAuth.token;
        if (token) {
            headers.set('Authorization', token);
        }
        return headers;
    },
});

export { baseQuery };

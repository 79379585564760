import Page from '../common/components/Page';
import CompanyHeader from './CompanyHeader';
import { Outlet } from 'react-router-dom';
import CompanyControlPanel from './CompanyControlPanel';
import styled from 'styled-components';
import { useCompanyHeaderControllerType } from './CompanyUsersPage/hooks/useCompanyHeaderController';
import { useCompanyPageControllerType } from './hooks/useCompanyPageController';
import { useCompanyControlPanelControllerType } from './hooks/useCompanyControlPanelController';
import { Stack } from '@mui/material';
import { useSelectedCompany } from './hooks/useSelectedCompany';
import { NotFoundError } from 'design-system';
import { useEffect } from 'react';
import { useCompanyUsersRepository } from '../common/hooks/useCompanyUsersRepository';

const Content = styled(Stack)`
    height: 100%;
`;

const CompanyPage = ({
    useController,
    useCompanyHeaderController,
    useCompanyControlPanelController,
}: {
    useController: useCompanyPageControllerType;
    useCompanyHeaderController: useCompanyHeaderControllerType;
    useCompanyControlPanelController: useCompanyControlPanelControllerType;
}) => {
    const { useUpdateSelectedCompanyIdToMatchURL } = useController();
    useUpdateSelectedCompanyIdToMatchURL();
    const { exists } = useSelectedCompany();
    const { fetch } = useCompanyUsersRepository();

    useEffect(() => {
        fetch();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return exists ? (
        <Page>
            <Page.Header>
                <CompanyHeader useController={useCompanyHeaderController} />
            </Page.Header>
            <Page.Content>
                <Content data-testid={'t-content-with-navbar'}>
                    <CompanyControlPanel useController={useCompanyControlPanelController} />
                    <Outlet />
                </Content>
            </Page.Content>
        </Page>
    ) : (
        <NotFoundError action={() => (window.location.href = '/companies')} />
    );
};

export default CompanyPage;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Company from '../../domain/Companies/Company';
import { RootState } from '../store';
import { CompaniesAPI } from '../api/companies/CompaniesAPI';

export const fetchCompanies = createAsyncThunk('companies/fetchCompanies', async (_, { getState }) => {
    const { getAll } = CompaniesAPI();
    const state = getState() as RootState;
    return getAll(state.userAuth.token);
});

interface CompaniesState {
    companies: Company[];
    selectedCompanyId?: string;
    isLoading: boolean;
}

const initialState: CompaniesState = {
    companies: [],
    selectedCompanyId: undefined,
    isLoading: false,
};

const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        updateCompanies: (state, action: PayloadAction<Company[]>) => {
            state.companies = action.payload;
        },
        updateSelectedCompanyId: (state, action: PayloadAction<string | undefined>) => {
            state.selectedCompanyId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCompanies.fulfilled, (state, action) => {
            state.companies = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchCompanies.rejected, (state) => {
            state.companies = [];
            state.isLoading = false;
        });
        builder.addCase(fetchCompanies.pending, (state) => {
            state.isLoading = true;
        });
    },
});

export const selectSelectedCompanyExists = (state: RootState) => {
    const selectedCompanyId = selectSelectedCompanyId(state);
    if (!selectedCompanyId) {
        return false;
    }
    return state.companies.companies.some((company) => company.id === selectedCompanyId);
};

export const { updateCompanies, updateSelectedCompanyId } = companiesSlice.actions;

export const selectCompanies = (state: RootState) => {
    return state.companies.companies;
};

export const selectSelectedCompanyId = (state: RootState) => {
    return state.companies.selectedCompanyId;
};

export const selectSelectedCompanyName = (state: RootState) => {
    const selectedCompanyId = selectSelectedCompanyId(state);
    return state.companies.companies.find((company) => company.id === selectedCompanyId)?.name;
};

export const selectIsLoading = (state: RootState) => {
    return state.companies.isLoading;
};

export default companiesSlice.reducer;

import { HttpClient, HttpMethod } from '../../common/api/HttpClient';
import useUserAuth from '../../common/hooks/useUserAuth';
import { CompanyCreationRequestDTO } from '../repositories/CompaniesRepository';
import { CompanyCreationRequestDTOMapper } from './CompanyCreationRequestDTOMapper';

export const CompaniesDataSource = () => {
    const { fetch } = HttpClient();
    const { userAuth } = useUserAuth();
    const { map } = CompanyCreationRequestDTOMapper();

    const createCompany = async (company: CompanyCreationRequestDTO) => {
        const body = map(company);
        const result = await fetch(HttpMethod.POST, '/api/3/companies', userAuth.token, body);
        if (result.status !== 201) {
            throw Error(result.body.message);
        }
    };

    const getCRMConnectionURL = async (companyId: string): Promise<string> => {
        const result = await fetch(HttpMethod.GET, `/api/3/crm/companies/${companyId}`, userAuth.token);
        if (result.status !== 200) {
            throw Error(result.body.message);
        }
        return result.body.connection_url;
    };

    return { createCompany, getCRMConnectionURL };
};

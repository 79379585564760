import { useSelectCompany } from './useSelectCompany';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
    fetchCompanyUsers,
    selectCompanyUsers,
    selectIsLoadingCompanyUsers,
} from '../../store/slices/companyUsersSlice';

const useCompanyUsersRepository = () => {
    const { selectedCompanyId } = useSelectCompany();
    const dispatch = useAppDispatch();
    const users = useAppSelector(selectCompanyUsers);
    const isLoading = useAppSelector(selectIsLoadingCompanyUsers);

    const fetch = () => {
        if (selectedCompanyId) dispatch(fetchCompanyUsers(selectedCompanyId));
    };

    return { users, isLoading, fetch };
};

export { useCompanyUsersRepository };

import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { fetchCompanyUsers } from './companyUsersSlice';
import { WritableDraft } from 'immer/dist/internal';
import { ERROR_BOUNDARY_HANDLED_ERROR_CODES } from '../hooks';
import { fetchCompanies } from './companiesSlice';
import { createCompanyCrmUsers, fetchCompanyCrmUsers } from '../middleware/companyCrmUsers/companyCrmUsersThunks';

export const enum MessageSeverity {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    INFO = 'INFO',
}

export const enum MessageType {
    DIALOG = 'DIALOG',
    SERVER_ERROR_DIALOG = 'SERVER_ERROR_DIALOG',
    CLIENT_ERROR_DIALOG = 'CLIENT_ERROR_DIALOG',
    SNACKBAR = 'SNACKBAR',
}

export const enum ActionType {
    NAVIGATE = 'NAVIGATE',
}

export type Message = {
    id: string;
    type: MessageType;
    severity: MessageSeverity;
    title: string;
    content: string;
    actionType?: ActionType;
    actionParameters?: any;
};

interface FeedbackState {
    messages: Message[];
}

const initialState: FeedbackState = {
    messages: [],
};

const feedbackSlice = createSlice({
    name: 'route',
    initialState,
    reducers: {
        addMessage: (state, action: PayloadAction<Message>) => {
            state.messages = [...state.messages, action.payload];
        },
        clearMessage: (state, action: PayloadAction<string>) => {
            state.messages = state.messages.filter((message) => message.id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCompanyUsers.rejected, (state, action) => {
            handleGenericError(state, action.error);
        });

        builder.addCase(fetchCompanies.rejected, (state, action) => {
            handleGenericError(state, action.error);
        });

        builder.addCase(createCompanyCrmUsers.rejected, (state, action) => {
            handleGenericError(state, action.error);
        });

        builder.addCase(fetchCompanyCrmUsers.rejected, (state, action) => {
            handleGenericError(state, action.error);
        });
    },
});

const handleGenericError = (state: WritableDraft<FeedbackState>, error: SerializedError) => {
    const message = buildGenericErrorMessage(error);
    if (message) {
        state.messages = [...state.messages, message];
    }
};

export const buildGenericErrorMessage = (error: { message?: string }) => {
    if (error.message && ERROR_BOUNDARY_HANDLED_ERROR_CODES.includes(error.message)) {
        return; // Let the error boundary handle this
    }
    switch (Math.floor(Number(error.message) / 100)) {
        case 4:
            return buildClientError(error);
        case 5:
            return buildServerError(error);
        default:
            return;
    }
};

const buildClientError = (error: any) => ({
    id: 'clientError',
    type: MessageType.CLIENT_ERROR_DIALOG,
    title: error.message,
    content: '',
    severity: MessageSeverity.WARNING,
    actionType: ActionType.NAVIGATE,
    actionParameters: '/',
});

const buildServerError = (error: any) => ({
    id: 'serverError',
    type: MessageType.SERVER_ERROR_DIALOG,
    title: error.message,
    content: '',
    severity: MessageSeverity.WARNING,
    actionType: ActionType.NAVIGATE,
    actionParameters: '/',
});

export const buildSnackbarMessage = (
    message: string,
    severity: MessageSeverity,
    actionType?: ActionType,
    actionParameters?: any,
    buttonText?: string
) => ({
    id: 'snackbar',
    type: MessageType.SNACKBAR,
    title: buttonText || '',
    content: message,
    severity,
    actionType,
    actionParameters,
});

export const { addMessage, clearMessage } = feedbackSlice.actions;

export const selectMessages = (state: RootState) => {
    return state.feedback.messages;
};

export default feedbackSlice.reducer;

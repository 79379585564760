import { useSelectCompany } from './useSelectCompany';
import CompanyDefinition from '../../domain/CompanyDefinition/CompanyDefinition';
import {
    useGetCompanyDefinitionQuery,
    useUpdateCompanyDefinitionMutation,
} from '../../store/api/companyDefinition/CompanyDefinitionAPI';
import { skipToken } from '@reduxjs/toolkit/query';

const useCompanyDefinitionRepository = () => {
    const { selectedCompanyId } = useSelectCompany();

    const { data: companyDefinition } = useGetCompanyDefinitionQuery(selectedCompanyId ?? skipToken);

    const [updateCompanyDefinition, info] = useUpdateCompanyDefinitionMutation();
    const doEditCompanyDefinition = (newCompanyDefinition: CompanyDefinition) => {
        if (selectedCompanyId) {
            updateCompanyDefinition({ companyId: selectedCompanyId, companyDefinition: newCompanyDefinition });
        }
    };

    return { companyDefinition, editCompanyDefinition: doEditCompanyDefinition, isSaving: info.isLoading };
};

export { useCompanyDefinitionRepository };

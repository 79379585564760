import { ROW_COLUMN_SEPARATOR } from '../defs';

export const duplicated = (uuid: string, value: any, values: any) => {
    if (value) {
        for (const key in values) {
            const parts = key.split(ROW_COLUMN_SEPARATOR);
            if (parts.length > 1) {
                if (parts[0] === uuid) {
                    continue;
                }
                if (parts[1] === 'name' && values[key] === value) {
                    return false;
                }
            }
        }
    }
    return true;
};

import { User } from '../../domain/Users/User';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CompanyUsersAPI } from '../api/companyUsers/CompanyUsersAPI';

export const fetchCompanyUsers = createAsyncThunk(
    'companyUsers/fetchCompanyUsers',
    async (companyId: string | undefined, { getState, rejectWithValue }) => {
        const { getAllForCompany } = CompanyUsersAPI();
        const { userAuth } = getState() as RootState;
        return await getAllForCompany(companyId, userAuth.token);
    }
);

interface CompanyUsersState {
    users: User[];
    isLoading: boolean;
}

const initialState: CompanyUsersState = {
    users: [],
    isLoading: false,
};

const companyUsersSlice = createSlice({
    name: 'companyUsers',
    initialState,
    reducers: {
        updateUsers: (state, action: PayloadAction<User[]>) => {
            state.users = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCompanyUsers.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCompanyUsers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.users = action.payload;
        });
        builder.addCase(fetchCompanyUsers.rejected, (state) => {
            state.isLoading = false;
            state.users = [];
        });
    },
});

export const { updateUsers } = companyUsersSlice.actions;

export const selectCompanyUsers = (state: RootState) => {
    return state.companyUsers.users;
};

export const selectIsLoadingCompanyUsers = (state: RootState) => {
    return state.companyUsers.isLoading;
};

export default companyUsersSlice.reducer;

import { createApi } from '@reduxjs/toolkit/query/react';
import { mapFromAPI, mapToAPI } from './companyDefinitionMapper';
import CompanyDefinition from '../../../domain/CompanyDefinition/CompanyDefinition';
import { baseQuery } from '../commonAPIConfig';

export const CompanyDefinitionAPI = createApi({
    reducerPath: 'CompanyDefinitionApi',
    baseQuery,
    tagTypes: ['CompanyDefinition'],
    endpoints: (builder) => ({
        getCompanyDefinition: builder.query<CompanyDefinition, string>({
            query: (companyId) => `/api/3/records/companies/${companyId}/company-definition`,
            transformResponse: (response) => mapFromAPI(response),
            providesTags: (result, error, companyId) => [{ type: 'CompanyDefinition', companyId }],
        }),
        updateCompanyDefinition: builder.mutation<
            CompanyDefinition,
            { companyId: string; companyDefinition: CompanyDefinition }
        >({
            query: (data) => ({
                url: `/api/3/records/companies/${data.companyId}/company-definition`,
                method: 'PUT',
                body: mapToAPI(data.companyDefinition),
            }),
            transformResponse: (response) => mapFromAPI(response),
            invalidatesTags: (result, error, { companyId }) => [{ type: 'CompanyDefinition', companyId }],
        }),
    }),
});

export const { useGetCompanyDefinitionQuery, useUpdateCompanyDefinitionMutation } = CompanyDefinitionAPI;

import { fetchCompanies, selectCompanies, selectIsLoading } from '../../store/slices/companiesSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const useCompaniesRepository = () => {
    const dispatch = useAppDispatch();
    const companies = useAppSelector(selectCompanies);
    const isLoading = useAppSelector(selectIsLoading);

    const fetchAll = () => dispatch(fetchCompanies());

    return { companies, isLoading, fetchAll };
};

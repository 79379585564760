import styled from 'styled-components';
import { GridCellParams, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import MultiUserSelector from './MultiUserSelector';
import { useCompanyUsersMainContentControllerType } from './hooks/useCompanyUsersMainContentController';
import { useMultiUserSelectorControllerType } from './hooks/useMultiUserSelectorController';
import { UserState } from '../../domain/Users/User';
import { ActiveCompanyIcon, InactiveCompanyIcon } from '../../common/components/ActiveStatusComponent';
import Button, { BUTTON_LEVELS, ButtonVariants } from '../../common/components/DS/Button';
import { StyledDataGrid } from '../../common/components/StyledDataGrid';
import CircularProgress from '@mui/material/CircularProgress';

const columns: GridColDef[] = [
    {
        field: 'username',
        flex: 1,
        headerName: 'Username',
        headerAlign: 'left',
        align: 'left',
    },
    {
        field: 'firstName',
        flex: 1,
        headerName: 'Name',
        headerAlign: 'left',
        align: 'left',
    },
    {
        field: 'lastName',
        flex: 1,
        headerName: 'Surname',
        headerAlign: 'left',
        align: 'left',
    },
    {
        field: 'id',
        flex: 1,
        headerName: 'User ID',
        headerAlign: 'left',
        align: 'left',
    },
    {
        field: 'email',
        flex: 1,
        headerName: 'Email',
        headerAlign: 'left',
        align: 'left',
    },
    {
        field: 'dateJoined',
        flex: 1,
        headerName: 'Date joined',
        headerAlign: 'left',
        align: 'left',
    },
    {
        field: 'state',
        flex: 1,
        headerName: 'Active',
        headerAlign: 'left',
        align: 'left',
        renderCell: (params: GridCellParams) =>
            params.value === UserState.ACTIVE ? <ActiveCompanyIcon /> : <InactiveCompanyIcon />,
    },
];

const StyledBox = styled(Box)`
    display: flex;
    flex-flow: column;
    background-color: white;
    width: 100%;
    height: 100%;
`;

const StyledBoxDataGridContainer = styled(Box)`
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
    flex: 1;
`;

const ControlsContainer = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 30px;
    height: 60px;
    gap: 10px;
`;

const ProgressContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
    width: 100%;
    height: 100%;
`;

const CompanyUsersMainContent = ({
    useCompanyUsersMainContentController,
    useMultiUsersSelectorController,
}: {
    useCompanyUsersMainContentController: useCompanyUsersMainContentControllerType;
    useMultiUsersSelectorController: useMultiUserSelectorControllerType;
}) => {
    const { showAddUsersModal, setShowAddUsersModal, onConfirm, users, connectToCRM, isLoadingUsers } =
        useCompanyUsersMainContentController();

    const renderConnectToCRMButton = () => (
        <Button variant={ButtonVariants.CONTAINED} onClick={connectToCRM} severity={BUTTON_LEVELS.INFO}>
            <div data-testid="connect-to-crm-caption">Connect to CRM</div>
        </Button>
    );

    const renderAddUsersButton = () => (
        <Button
            variant={ButtonVariants.CONTAINED}
            onClick={() => setShowAddUsersModal(true)}
            severity={BUTTON_LEVELS.INFO}
        >
            <div data-testid="add-users-button-caption">Add users</div>
        </Button>
    );

    const renderAddUsersModal = () => (
        <MultiUserSelector
            visible={showAddUsersModal}
            useController={useMultiUsersSelectorController}
            onConfirm={onConfirm}
            onClose={() => setShowAddUsersModal(false)}
            selectLabel="Select"
            selectedLabel="Selected to be retrieved"
            description="User full name / Email"
        />
    );
    return isLoadingUsers ? (
        <ProgressContainer>
            <CircularProgress />
        </ProgressContainer>
    ) : (
        <>
            {showAddUsersModal && renderAddUsersModal()}
            <StyledBox>
                <StyledBoxDataGridContainer>
                    <ControlsContainer>
                        {renderConnectToCRMButton()}
                        {renderAddUsersButton()}
                    </ControlsContainer>

                    {/* columnBuffer is needed to avoid virtualization of the page which makes the test not render all the columns. Check https://stackoverflow.com/questions/65669707/problem-testing-material-ui-datagrid-with-react-testing-library  */}
                    <StyledDataGrid
                        rows={users}
                        columns={columns}
                        columnBuffer={8}
                        disableRowSelectionOnClick
                        slots={{ toolbar: GridToolbarQuickFilter }}
                        localeText={{ toolbarQuickFilterPlaceholder: 'Search User by Name, ID or Email' }}
                        slotProps={{
                            toolbar: {
                                sx: {
                                    width: '400px',
                                },
                            },
                        }}
                    />
                </StyledBoxDataGridContainer>
            </StyledBox>
        </>
    );
};

export default CompanyUsersMainContent;

import type { AnyAction, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { addMessage, buildGenericErrorMessage } from '../slices/feedbackSlice';

const getErrorCode = (error: any) => {
    return error.data ? error.status : error.code;
};

//  for some endpoints 404 is a valid (expected) response
const isWhitelisted = (action: AnyAction) => {
    return action.meta?.arg?.endpointName === 'getCompanyDefinition' && getErrorCode(action.payload) === 404;
};

export const rtkQueryErrorHandler: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action) && !isWhitelisted(action)) {
        const code = getErrorCode(action.payload);
        const message = buildGenericErrorMessage({ message: code });
        if (message) api.dispatch(addMessage(message));
    }

    return next(action);
};
